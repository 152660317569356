import React, { useState } from 'react';
import './App.css';

function App() {
  const backgroundImage = `${process.env.PUBLIC_URL}/background.png`;
  const logoImage = `${process.env.PUBLIC_URL}/zazzleslogo.jpg`;
  const eye1Image = `${process.env.PUBLIC_URL}/dexscreener.png`;
  const eye2Image = `${process.env.PUBLIC_URL}/pumplogo.png`;
  const noseImage = `${process.env.PUBLIC_URL}/raydium-removebg-preview.png`;
  const telegramImage = `${process.env.PUBLIC_URL}/telegramlogo.png`;
  const xImage = `${process.env.PUBLIC_URL}/xlogo.png`;
  const image3Image = `${process.env.PUBLIC_URL}/image3.jpg`;

  const [copied, setCopied] = useState(false);

  const handleCopy = (e) => {
    navigator.clipboard.writeText('CkjNG3oL6C974LyK4ZidMtiGphFVPZdt7bETj7WEpump');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="App">
      <div className="background-container" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      <div className="content">
        <img src={logoImage} alt="Logo" style={{ borderRadius: '20px', width: '50%', height: '50%' }} />
        
        <div className="icons-bar">
          <a href="https://dexscreener.com/solana/4n2aqyxnv8gk9cvzeerk21rktpeob6ejx4ulege8atxc" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={eye1Image} alt="Dexscreener Logo" className="icon-image" />
          </a>
          <a href="https://pump.fun/CkjNG3oL6C974LyK4ZidMtiGphFVPZdt7bETj7WEpump" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={eye2Image} alt="Pump.fun Logo" className="icon-image" />
          </a>
          <a href="https://raydium.io/swap/?inputMint=sol&outputMint=CkjNG3oL6C974LyK4ZidMtiGphFVPZdt7bETj7WEpump" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={noseImage} alt="Nose Logo" className="icon-image" />
          </a>
          <a href="https://t.me/zazzlesofficialcto" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={telegramImage} alt="Telegram Logo" className="icon-image" />
          </a>
          <a href="https://x.com/solzazzles/" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={xImage} alt="X Logo" className="icon-image" />
          </a>
        </div>

        <footer className="App-footer" onClick={handleCopy}>
          <div className="image3-container">
            <p className="contract-address">CA: CkjNG3oL6C974LyK4ZidMtiGphFVPZdt7bETj7WEpump</p>
            {copied && <span className="copied-message">Copied!</span>}
            <img src={image3Image} alt="Image3" className="image3" />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;